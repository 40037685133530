.contentArea {
  flex-grow: 1;
  min-height: 85vh;
  padding: 1%;
}

.mainArea {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  width: 100%;
  height: 100%;
}
