.completed {
  text-decoration: line-through;
  color: var(--cyan);
}

.itemBox {
  border-width: thin;
  border-style: solid;
  display: flex;
}

.dueDateTag {
  box-shadow: none;
  margin-top: 0;
}
.priorityTag {
  box-shadow: none;
  display: flex;
  align-items: baseline;
  margin-top: 0;
}

.doneButton {
  display: flex;
}

.itemButtons {
  border: none;
  cursor: pointer;
  background-color: var(--blackSecondary)
}

.info {
  display: flex;
  justify-content: space-between;
  width: 100%;
  flex-flow: column;
}

.content {
  margin-right: 10px;
  margin-bottom: 10px;
  width: 100%;
  word-break: break-all;
}

.title {
  width: 100%;
  max-width: 100%;
  font-size: 16px;
  line-height: 18px;
  font-weight: 700;
  margin-bottom: 10px;
}

.description {
  width: 100%;
  word-break: break-all;
}

.additionalWrapper {
  display: flex;
  flex-flow: column;
  justify-content: space-between;
  align-self: flex-end;
  width: 100%;
  height: 100%;
}

.tagWrapper {
  display: flex;
  margin: 0 0 10px 0;
  white-space: nowrap;
}

.buttonWrapper {
  display: flex;
  position: relative;
  align-self: flex-end;
}

.priorityLabel {
  text-transform: capitalize;
}

@media screen and (min-width: 400px) {
  .additionalWrapper {
    flex-flow: row;
  }
}
@media screen and (min-width: 576px) {
  .additionalWrapper {
    flex-flow: column;
  }
}
@media screen and (min-width: 768px) {
  .additionalWrapper {
    flex-flow: row;
  }
}
@media screen and (min-width: 1280px) {
  .additionalWrapper {
    flex-flow: column;
    align-items: flex-end;
    width: initial;
  }
  .content {
    max-width: 500px;
  }
  .info {
    flex-flow: row;
  }
}
@media screen and (min-width: 1440px) {
  .content {
    max-width: 850px;
  }
}
