.sideBar {
  display: flex;
  justify-content: space-between;
  border-width: 1px;
  border-style: solid;
  align-items: center;
  flex-direction: column;
  width: 100%;
}

.importBox {
  background-color: var(--black);
  border-radius: 1em;
  padding: 1em;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 999999;
  border-style: solid;
  border-color: var(--purple);
}
.importButtons {
  display: flex;
  justify-content: space-around;
  margin-top: 1em;
}
