.tooltipContainer {
  position: relative;
  display: inline-block;
}

.tooltipText {
  visibility: hidden;
  width: 120px;
  background-color: var(--purple);
  color: var(--white);
  text-align: center;
  padding: 5px 0;
  border-radius: 10px; /* Rounded corners at the top, pointed corner at the bottom */

  position: absolute;
  z-index: 1;
  bottom: 125%; /* Adjusts the height of the tooltip */
  left: 50%;
  margin-left: -60px;

  opacity: 0;
  transition: opacity 0.3s;
}

/* Creates a triangle at the bottom of the tooltip */
.tooltipText::after {
  content: '';
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: var(--purple) transparent transparent transparent;
}

.tooltipContainer:hover .tooltipText {
  visibility: visible;
  opacity: 1;
}
