.footerContainer {
  width: 100%;
  text-align: center;
  padding: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 1em;
  flex-shrink: 0;
}
