.divider {
  padding-bottom: 20px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 90%;
  margin-left: 5%;
}

.dividerLine {
  height: 1px;
  background-color: var(--purple);
  opacity: 0.15;
  flex-grow: 1;
}

.dividerTitle {
  text-transform: uppercase;
  color: var(--purple);
  margin: 0 10px;
}

.hideOption {
  color: var(--purple);
  border: thin solid var(--purple);
  border-radius: 15%;
  cursor: pointer;
}

