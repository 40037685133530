.topBarContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  flex-shrink: 0;
}

.logo {
  display: flex;
  align-items: center;
  text-decoration: none;
}

.navItems {
  display: flex;
  gap: 15px;
  color: var(--black);
}
.navItems a {
  color: var(--black);
}
.navItemsFloor {
  align-items: center;
}

.item {
  text-decoration: none;
}
