.profileCard {
  height: 100%;
  padding: 0;
  background-color: var(--black);
  border-radius: 1em;
}
.profileInformation {
  padding: 30px 40px;
  border-bottom: 1px solid var(--purple);
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
}
.profileName {
  font-weight: 900;
  text-transform: uppercase;
  margin: 0;
  line-height: 18px;
}
.profileContact {
  margin-top: 0;
  margin-bottom: 5px;
  line-height: 18px;
}
.profileButtons {
  margin-top: 10px;
  margin-bottom: 0;
  padding: 8px 15px;
}
.profileStats {
  display: flex;
  justify-content: space-around;
}
.profileStat {
  text-align: center;
  padding-top: 5px;
  padding-bottom: 15px;
}
.profileStatNumber {
  color: var(--green);
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  margin: 0;
  margin-right: 10px;
}
.profileStatTitle {
  margin: 0;
  color: var(--purple);
  font-size: 12px;
  line-height: 14px;
}
.profileTabLink {
  cursor: pointer;
}
.tabBox {
  background-color: var(--black);
  border-radius: 1em;
  padding: 1em;
  height: 85vh;
}
.confirmBox {
  background-color: var(--black);
  border-radius: 1em;
  padding: 1em;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 999999;
  border-style: solid;
  border-color: var(--purple);
}
.confirmButtons {
  display: flex;
  justify-content: space-around;
  margin-top: 1em;
}

/* Media */
@media (max-width: 1345px) and (min-width: 1200px) {
  .profileInformation {
    padding: 30px 15px;
  }
}

@media screen and (max-width: 360px) {
  .profileInformation {
    width: 100%;
    flex-direction: column;
    align-items: center;
  }
  .profileData {
    width: 100%;
    display: flex;
    flex-direction: column;
    text-align: center;
    padding: 0;
  }
}
